import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import ThreeCol from '../components/ThreeColLayout'
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import Parse from '../vendor/Parse'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigate, Link } from "gatsby"
import useAuth from "../hooks/userAuth";
import Layout from "../components/layout";
const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  minHeight: '100% !important',
  '& .card': {
    maxWidth: 800,
    minHeight: 400,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center',
  },
}));

// inital login credentials
const initialValues = {
  email: '',
  password: '',
  remember: true,
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be 6 character length')
    .required('Password is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
});





const JwtLogin = () => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(typeof window !== `undefined` && window?.location?.search)
  const authCode = queryParams.get("o")
  const email = queryParams.get("e")
  const { login, setMessage } = useAuth()

  const dispatch = useDispatch()

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await login(values?.email, values?.password)
      navigate('/')
    } catch (e) {
      setMessage(e.message)
      setLoading(false);
    }
    
    setLoading(false);
  };

  useEffect(async () => {
    if (authCode && email) {
      const response = await Parse.Cloud.run('verifyEmailAddress', {
        email: email,
        otp: authCode
      })
      console.log(response)
    }
  }, [authCode && email])

  return (
    <Layout>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
            
            </JustifyBox>
          </Grid>

          <Grid item sm={12} xs={12}>
            <ContentBox>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 1.5 }}
                    />

                    <FlexBox justifyContent="space-between">

                    <Link to="/register">Register</Link>
                    <Link to="/forgotPassword">Forget Password</Link> 
                    </FlexBox>

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}
                    >
                      Login
                    </LoadingButton>

                  </form>
                )}
              </Formik>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </Layout>
  );
};

export default JwtLogin;
